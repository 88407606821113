
* {
    font-family: 'Helvetica Neue', 'Helvetica', sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #1A1A1A;
    margin: 0;
    padding: 0;
}

html {
    overflow-x: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

body {
    background-color: #FFFBF7;
}

h1 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

h2 {
    font-family: 'Helvetica Neue', sans-serif;
    font-weight: bold;
    font-size: 14px;
    margin-top: 20px;
}

table {
    width: 100%;
    white-space: nowrap;
    border: none;
    margin-top: 20px;
}

#table1 {
    max-width: var(--source-width);
    width: 70%;
    max-width: 400px;
}

thead tr td {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    padding-right: 10px;
}

.table-row:hover {
    cursor: pointer;
    background-color: #EFEFEF;
}

#date-header {
    padding-right: 80px;
}

#variety-header {
    padding-right: 45px;
}

tr {
    height: 22px;
}

.logo {
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.logo:hover {
    cursor: pointer;
}

.main {
    max-width: 780px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.container {
    width: 780px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.card {
    border: 3px solid #FFFBF7;
    border-radius: 20px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.067);
    padding: 17px;
    margin: 0px 8px 16px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: hidden;
    width: 100%;
}

.head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loading-container {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

.loading-spinner {
    width: 50px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
    height: 20px;
}

.tooltip-container .tooltip {
    opacity: 0;
    position: absolute;
    left: -70px;
    z-index: 100;
    transition: opacity ease-out 0.5s;
    transform: translateY(25%);
}

.tooltip-container:hover .tooltip {
    animation: 1s delay;
    animation-fill-mode: forwards;
}

.icon {
    height: 20px;
    transition: opacity ease 0.3s;
}

.icon:hover {
    opacity: 0.5;
    cursor: pointer;
}

.description {
    width: 80%;
}

.export {
    width: 20px;
    cursor: pointer;
    opacity: 1;
    transition: opacity ease 0.3s;
}

.export:hover {
    opacity: 0.5;
}

.filters {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: auto;
    flex-wrap: wrap;
}

button {
    background-color: #EFEFEF;
    border: 2px solid #FFFBF7;
    border-radius: 15px;
    width: 120px;
    height: 40px;
    transition: opacity ease 0.3s;
    transition: border ease 0.3s, opacity ease 0.3s;
}

button:hover {
    cursor: pointer;
    opacity: 0.5;
}

#leftDrop {
    border-radius: 15px 0px 0px 15px;
    border-right: 2px solid #FFFBF7;
}

#rightDrop {
    border-radius: 0px 15px 15px 0px;
    border-left: 2px solid #FFFBF7;
}

.dropBtn {
    background-color: #EFEFEF;
    width: 58px;
    height: 40px;
    transition: opacity ease 0.3s;
    text-align: center;
    line-height: 40px;
}

.dropBtn:hover {
    cursor: pointer;
    opacity: 0.5;
}

.dropdown {
    display: flex;
}

input {
    background-color: #EFEFEF;
    border: 2px solid #FFFBF7;
    border-radius: 15px;
    width: 30%;
    height: 40px;
    transition: opacity ease 0.3s;
    padding-left: 5%;
    padding-right: 5%;
    transition: border ease 0.3s, opacity ease 0.3s;
}

.date-wrapper {
    border-radius: 15px;
    width: 46%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity ease 0.3s, border-color ease 0.3s;
}

input[type="date"] {
    width: 100%;
}

input:hover {
    cursor: pointer;
    opacity: 0.5;
}

input:focus {
    outline: none;
    border: 2px solid #1a1a1a;
    opacity: 1;
}

.invalid:focus {
    background-color: #F47A7A;
}

#invalido {
    color: #F47A7A;
}

input::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.filters label {
    border-radius: 15px;
    border: 2px solid #FFFBF7;
    width: 46%;
    height: 40px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity ease 0.3s, border-color ease 0.3s;
}

#quad {
    width: 23%;
    transition: background-color ease 0.3s;
}

.filters label:hover {
    cursor: pointer;
    opacity: 0.7;
    background-color: #EFEFEF;
}

#filtercard {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
}

.filter-name {
    font-size: 14px;
    font-weight: 400;
    transition: opacity ease 0.3s;
    display: flex;
    align-items: center;
}

.filter-name:hover {
    cursor: pointer;
}

.filter-type {
    margin-top: 20px;
}

input[type="radio"] {
    display: none;
}

#lingon:checked + label {
    background-color: #F47A7A;
    opacity: 1;
}

#blueberries:checked + label {
    background-color: #8E8EEF;
    opacity: 1;
}

#web:checked + label {
    background-color: #ffc88d;
    opacity: 1;
}

#internal:checked + label {
    background-color: #ffc88d;
    opacity: 1;
}

#external:checked + label {
    background-color: #ffc88d;
    opacity: 1;
}

#machine:checked + label {
    background-color: #ffc88d;
    opacity: 1;
}

#compare:checked + label{
    background-color: #ffc88d;
    opacity: 1;
}

#both:checked + label{
    background-color: #ffc88d;
    opacity: 1;
}

#compareUpload:checked + label{
    background-color: #ffc88d;
    opacity: 1;
}

#bothUpload:checked + label{
    background-color: #ffc88d;
    opacity: 1;
}

#downloadBtn:hover {
    cursor: pointer;
}

.chart-wrapper {
    display: flex;
    justify-content: center;
    height: 100%;
}

.no-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wrapper {
    height: 100vh;
}

.images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
}

.imageBtn {
    padding-right: 6%;
    padding-left: 6%;
    width: 3%;
    transition: opacity ease 0.3s;
}

.imageBtn:hover {
    cursor: pointer;
    opacity: 0.5;
}

#photo {
    width: 70%;
    max-height: 350px;
    margin-top: 15px;
}

#photo:hover {
    cursor: zoom-in;
}

.one {
    display: flex;
    justify-content: space-between;
}

.two {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.three {
    display: flex;
    justify-content: space-between;
}

input[type="text"] {
    width: 100%;
    cursor: text;
}

input[type="text"],
textarea {
  touch-action: manipulation;
}

input[type="password"] {
    width: 100%;
    cursor: text;
}

.login {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.logout {
    background-color: #F47A7A;
    transition: opacity ease 0.3s;
}

.logout:hover {
    opacity: 0.5;
}

#loginUsername {
    width: calc(300px - 10%);
    text-align: center;
}

#loginPassword {
    width: calc(300px - 10%);
    text-align: center;
}

#loginIncorrect {
    color: #F47A7A;
    margin: 5px 0;
}

#loginSubmit {
    width: 300px;
    background-color: #ff9e29;
}

.privacy {
    background-color: rgba(0, 0, 0, 0);
}

#privacyPolicy {
    margin: 15px 10px;
    overflow-y: auto;
    height: 70vh;
}

#privacyPolicy p {
    text-align: justify;
}

ul {
    padding-left: 20px;
}

.loginBtn {
    background-color: #7CF29B;
}

.authcard {
    display: flex;
    justify-content: space-between;
}

.footer {
    margin-top: 70px;
}

.fullscreen {
    position: sticky;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fullscreen-images {
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

#fullscreen-photo {
    width: 50000px;
    height: 50000px;
    margin-top: 15px;
    max-height: 80vh;
    max-width: 80vw;
    position: relative;
}

.rotate:hover {
    cursor: zoom-out;
}

.no-rotate:hover {
    cursor: zoom-out;
}

.rotate {
    max-height: 80vw;
    max-width: 80vh;
    transform: rotate(90deg);
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
}

.no-rotate {
    max-width: 100%;
    max-height: 100%;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#fullscreen-table {
    width: 90%;
    margin-top: 5px;
}

#fullscreen-table td {
    text-align: center;
    padding-right: 0;
}

.fullscreen-imageBtn {
    min-width: 17px;
    max-width: 20px;
    width: 2%;
    transition: opacity ease 0.3s;
}

.fullscreen-imageBtn:hover {
    cursor: pointer;
    opacity: 0.5;
}

.extra {
    /* height: 150vh; */
    width: 100%;
    background-color: #1A1A1A;
}

@keyframes delay {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
  }
